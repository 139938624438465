import { FC } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { baseColors } from 'src/utils/constants/baseStyles';
import VideoUserRoom from '../CustomVisioVideo/VideoUserRoom';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'transparent',
    border: `2px solid ${baseColors.green.pastel}`,
    borderRadius: 2,
    boxShadow: 24,
    width: '100%',
    height: '100%',
};

interface IProps {
    toggleModal: any;
    open: any;
    roomName: string;
    userName: string;
    visioId: number;
    recordEnabled?: boolean,
}

const CustomVisioFramModal: FC<IProps> = ({ toggleModal, open, roomName, userName, visioId, recordEnabled }) => {
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={toggleModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <VideoUserRoom toggleModal={toggleModal} roomName={roomName} userCurrentName={userName} visioId={visioId} recordEnabled={recordEnabled}></VideoUserRoom>
                </Box>
            </Fade>
        </Modal>
    );
};

export default CustomVisioFramModal;
