import { PhotoCamera } from '@mui/icons-material';
import { Avatar, Grid, IconButton } from '@mui/material';
import { createRef, FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomText } from 'src/components/Text/CustomText';
import { baseColors, baseFontFamily, baseFontSize } from 'src/utils/constants/baseStyles';
import { ROUTES } from 'src/routes/routes';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { CustomBadge } from 'src/components/Badge/CustomBadge';
import { CustomSelect } from 'src/components/InputField/CustomSelect';
import { Spacer } from 'src/components/Spacer/Spacer';
import useCheckUserRole from 'src/hooks/useCheckUserRole';
import { canSaveAsDraft } from 'src/views/Programme/Form/Logic';
interface ProgramFormHeaderProps {
    icon?: any;
    isSynthesis?: boolean;
    setProgram?: any;
    level?: any[];
    rerender?: boolean;
    program?: any;
    onDetailsPage?: boolean;
    setShowLeaveConfirm?: any;
    setTargetLocation?: any;
}

export const ProgramFormHeader: FC<ProgramFormHeaderProps> = (props) => {
    const { icon, setProgram, isSynthesis, level, program, onDetailsPage, setShowLeaveConfirm, setTargetLocation } =
        props;
    const history = useHistory();
    const { roles } = useCheckUserRole();
    const isCoordinator = roles?.isCoordinator ?? false;
    const [rerender, setRerender] = useState<boolean>(false);
    const handleClickProgramList = () => {
        if (
            canSaveAsDraft(program) &&
            typeof setShowLeaveConfirm === 'function' &&
            typeof setTargetLocation === 'function'
        ) {
            setShowLeaveConfirm(true);
            setTargetLocation(ROUTES.programmes);
        } else {
            history.push(ROUTES.programmes);
        }
    };
    const iconRef = createRef<HTMLInputElement>();
    const [isSynthes, setIsSynthes] = useState<boolean>(false);
    const programId = program.id;

    const _handleReaderLoaded = (readerEvt: any) => {
        let binaryString = readerEvt.target.result;
        setProgram((old: any) => ({ ...old, icon: `data:image/jpeg;base64,${btoa(binaryString)}` }));
    };

    useEffect(() => {
        const str = history.location.pathname;
        if (
            str.includes('synthese') ||
            str.includes('process') ||
            str.includes('goals') ||
            str.includes('dispense') ||
            str.includes('evaluation')
        ) {
            setIsSynthes(true);
        } else {
            setIsSynthes(false);
        }
    }, [history.location]);

    const handleIconChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let files = event.currentTarget.files;
        if (files && files?.length > 0) {
            let file = files[0];

            let allowedExtension = ['image/png', 'image/jpeg', 'image/jpg'];
            if (!allowedExtension.includes(file.type)) {
                alert('images non supporté (veuiller importe des .jpeg, .jpg, .png');
            } else {
                const reader = new FileReader();
                reader.onload = _handleReaderLoaded;
                reader.readAsBinaryString(file);
            }
        }
    };
    //need an api to get this;
    const editProgram = () => {
        history.push({ pathname: `/programmes/${programId}/edit`, state: { program: program } });
    };
    const gestionProgram = () => {
        history.push(`/programmes/${programId}/manage/activity`);
    };
    const archiveProgram = () => {
        history.push({ pathname: `/programmes/${programId}/archive`, state: { program: program } });
    };
    const hasIcon = program?.icon && program?.icon !== '';

    return (
        <Grid item style={{ marginTop: 20, paddingRight: 20 }}>
            <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Grid container>
                        <Grid item>
                            <IconButton
                                onClick={() => iconRef.current?.click()}
                                style={{
                                    marginLeft: '22%',
                                    background: baseColors.grey,
                                    padding: hasIcon ? 0 : 20,
                                    marginBottom: 10,
                                }}
                                disabled={isSynthesis}
                            >
                                {hasIcon ? (
                                    <Avatar sx={{ height: 85, width: 85 }} src={program.icon} />
                                ) : (
                                    <PhotoCamera sx={{ width: 50, height: 50 }} />
                                )}

                                <input ref={iconRef} id="icon_input" type="file" hidden onChange={handleIconChange} />
                            </IconButton>

                            {!isSynthesis && (
                                <CustomText fontSize={baseFontSize.mini} fontFamily={baseFontFamily.OpenSansSemiBold}>
                                    {icon === '' ? 'Cliquer pour ajouter une icône.' : "Cliquer pour modifier l'icône."}
                                </CustomText>
                            )}
                        </Grid>

                        <Grid item style={!isSynthesis ? {} : { marginLeft: 40 }}>
                            <CustomText fontFamily={baseFontFamily.BreeSerif} style={{ marginLeft: 10 }} fontSize={20}>
                                {program?.title ?? ''}
                            </CustomText>
                            <div style={{ marginTop: 20 }}>
                                {isSynthesis ? (
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <CustomBadge
                                            maxLength={`Programme ${program?.level?.name ?? 'initial'}`.length}
                                            title={`Programme ${program?.level?.name ?? 'initial'}`}
                                        />
                                        <CustomBadge
                                            isBold
                                            colors={{
                                                background: baseColors.orange.primary,
                                                fontColor: baseColors.white,
                                            }}
                                            maxLength={100}
                                            fontSize={12}
                                            title={`Questionnaire : ${program?.surveyParent?.name ?? ''}`}
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <CustomSelect
                                            rerender={rerender}
                                            onChange={(value: number) =>
                                                setProgram((old: any) => ({ ...old, level: value }))
                                            }
                                            defaultValue={program?.level ?? 0}
                                            labelKey={'name'}
                                            label=""
                                            items={level ? [{ id: 0, name: 'Sélectionner un niveau ' }, ...level] : []}
                                            idSelect="selection-niveau"
                                        />
                                    </div>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
                    className="program-buttons"
                >
                    {onDetailsPage &&
                        isCoordinator &&
                        (program?.state?.code === 'EN_CREATION' ||
                            program?.state?.code === 'BROUILLON' ||
                            !program?.preselected) && (
                            <CustomLoadingButton
                                loading={false}
                                handleClick={editProgram}
                                label="Modifier le programme"
                            />
                        )}

                    <Spacer width={20} />
                    {isSynthes && (
                        <>
                            <CustomLoadingButton
                                loading={false}
                                handleClick={gestionProgram}
                                label="gestion du programme"
                            />
                            <Spacer width={20} />
                        </>
                    )}

                    <Spacer width={20} />
                        <>
                            <CustomLoadingButton
                                loading={false}
                                handleClick={archiveProgram}
                                label="Archiver le programme"
                            />
                            <Spacer width={20} />
                        </>

                    <CustomLoadingButton
                        loading={false}
                        handleClick={handleClickProgramList}
                        label="Liste des Programmes"
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
